import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import mobile from "is-mobile";
import {
  HomeContainer,
  MainLogo,
  MobileHome,
  MobileHomeButtons,
  MobileHomeButtonsLine2,
} from "./style";
import { LANGS_MAP_FOR_ASSETS } from "../../utils/languages";
import { ScrollDownBtn } from "../../components";
import { useDMMPopupState } from "../../hooks/useDMMPopupState";

export default function Home() {
  const intl = useIntl();
  const { setIsOpen } = useDMMPopupState();
  const { locale } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];
  const handleReserve = () => {
    if (mobile({ tablet: true, featureDetect: true })) {
      window.location.href = "https://games.dmm.co.jp/detail/tenkafuma";
      return;
    }
    setIsOpen(true);
  };

  return (
    <div className="pt-10 md:pt-0">
      <HomeContainer id="home">
        <MainLogo>
          <img
            src={require(`../../assets/images/home/kv-logo-jp.png`).default}
            width={554}
            height={495}
            alt="main-logo"
          />
          <div className="text-center">
            <button id="pc-link" type="button" onClick={handleReserve}>
              <img
                src={require(`../../assets/images/mobile/btn-dl-ja-m@2x.png`).default}
                alt="pc-download"
                width={440}
                height={116}
              />
            </button>
          </div>
        </MainLogo>

        <MobileHome>
          <img
            src={require(`../../assets/images/mobile/kv-logo-m-${lang}.png`).default}
            alt="main-logo-mobile"
          />
          <MobileHomeButtons>
            <MobileHomeButtonsLine2>
              <button id="mobile-pc-link" type="button" onClick={handleReserve}>
                <img
                  src={require(`../../assets/images/mobile/btn-dl-ja-m@2x.png`).default}
                  alt="pc-download-mobile"
                />
              </button>
            </MobileHomeButtonsLine2>
          </MobileHomeButtons>
        </MobileHome>
        <ScrollDownBtn />
      </HomeContainer>
    </div>
  );
}
