import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import {useMediaQuery} from '@react-hookz/web';
import {
  CharacterListBg,
  CharacterListSection,
  CharacterListInnerSection,
  CharacterListSectionTitle,
  CharacterPreview,
} from "./style";
import sectionBgLeft from "../../assets/images/char-list-pic-01.png";
import sectionBgRight from "../../assets/images/char-list-pic-02.png";

const getGroupedDataList = (dataList, groups = [3, 3]) => {
  let result = [[]];
  let group_index = 0;

  let index = 0;
  dataList.forEach(data => {
    if (index < groups[group_index]) {
      result[result.length - 1].push(data);
      index++;
    } else if (index === groups[group_index]) {
      index = 1;
      group_index = group_index === 0 ? 1 : 0;
      result.push([data]);
    }
  });

  return result;
};


const CharacterGroupedList = ({ title, characterGroups = [] }) => (
  <CharacterListSection>
    <CharacterListSectionTitle>{title}</CharacterListSectionTitle>
    {characterGroups.map((characterGroup, group_index) => {
      return (
        <CharacterListInnerSection key={group_index}>
          {characterGroup.map(character => {
            const { type, slug, thumbImg } = character;
            return (
              <Link
                key={`character_${type}_${slug}`}
                id={`char-url-${type}-${slug}`}
                to={`/${type}/${slug}`}>
                <picture id={`char-preview-${type}-${slug}`}>
                  <source
                    srcSet={
                      require(`../../assets/images/characterListWebp${thumbImg.replace(
                        ".png",
                        ".webp"
                      )}`).default
                    }
                    type="image/webp"
                  />
                  <CharacterPreview
                    src={require(`../../assets/images/characterList${thumbImg}`).default}
                    alt="character-preview"
                  />
                </picture>
              </Link>
            );
          })}
        </CharacterListInnerSection>
      );
    })}
  </CharacterListSection>
);

export default function CharacterList() {
  // useStaticQuery does not accept variables
  // https://www.gatsbyjs.com/docs/how-to/querying-data/use-static-query/#known-limitations
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { type: { eq: "character" }, draft: { eq: false } } }
        sort: { fields: [frontmatter___order], order: DESC }
      ) {
        nodes {
          fileAbsolutePath
          frontmatter {
            type
            slug
            characterType
            order
            title
            thumbImg
          }
        }
      }
    }
  `);

  const { allMarkdownRemark } = data || {};
  const { nodes } = allMarkdownRemark || {};

  const isDesktop = useMediaQuery('(min-width: 1300px)', true);
  const isTablet = useMediaQuery('(min-width: 768px) and (max-width: 1299px)', true);

  const groups = isDesktop ? [7, 6] : isTablet ? [5, 4] : [3, 3];
  const normalGroup = getGroupedDataList(nodes.map(({frontmatter}) => frontmatter), groups);

  return (
    <>
      <CharacterListBg>
        <img src={sectionBgLeft} alt="character-list-pattern" />
        <img src={sectionBgRight} alt="character-list-pattern" />
      </CharacterListBg>

      <CharacterGroupedList
        title="- Characters -"
        characterGroups={normalGroup}
      />
    </>
  );
}
