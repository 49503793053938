import React, { useState } from "react";
import { useIntl } from "gatsby-plugin-intl";
import { PopUp } from "../../components";
import {
  IntroContainer,
  IntroTitle,
  IntroMainImage,
  IntroDescription,
  IntroContent,
  IntroPreviewImagesCenter,
  IntroPreviewImage,
} from "./style";
import introMainImg from "../../assets/images/intro/game-intro-chars.png";
import introStaticImg1 from "../../assets/images/intro/game-intro-01.png";
import introStaticImg2 from "../../assets/images/intro/game-intro-02.png";
import { LANGS_MAP_FOR_ASSETS } from "../../utils/languages";

export default function Intro() {
  const intl = useIntl();
  const { locale } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];

  const [showPopUp, setShowPopUp] = useState(false);
  const [bigImage, setBigImage] = useState(null);

  const handleClick = index => {
    // start from 3
    setBigImage(require(`../../assets/images/intro/popup-pic-00${index}.jpg`).default);
    setShowPopUp(true);
  };

  return (
    <>
      <IntroContainer id="intro">
        <IntroTitle>
          <img
            src={require(`../../assets/images/intro/caption-02-${lang}.png`).default}
            alt="intro-title"
          />
        </IntroTitle>
        <IntroMainImage src={introMainImg} alt="intro-main" />

        <IntroContent>
          <img className="intro__sideImage" src={introStaticImg1} alt="intro-static" />
          <IntroDescription
            src={require(`../../assets/images/intro/about-txt-${lang}.png`).default}
            alt="intro-description"
          />

          <img className="intro__sideImage" src={introStaticImg2} alt="intro-static" />
        </IntroContent>

        <IntroPreviewImagesCenter>
          {Array.from(Array(4).keys()).map(index => (
            <IntroPreviewImage
              onClick={() => handleClick(index + 3)}
              key={index}
              src={require(`../../assets/images/intro/game-intro-0${index + 3}.png`).default}
              alt="intro-preview"
            />
          ))}
        </IntroPreviewImagesCenter>
      </IntroContainer>

      {showPopUp && <PopUp img={bigImage} onClose={() => setShowPopUp(false)}></PopUp>}
    </>
  );
}
