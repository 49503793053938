import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS } from "../../utils/style";

const AgeVerificationContainer = styled.div`
  ${containerStyle}
  background: url("${process.env.GATSBY_ROOT_PATH}/bg-01.jpg") center/cover no-repeat;
  overflow: hidden;
  animation: fadeIn 0.5s;
`;

const AgeVerificationBlock = styled.div`
  width: 80%;
  max-width: 800px;
  min-height: 536px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(51, 1, 25, 0.88);
  padding: 55px 30px 20px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 90%;
    height: 95vh;
    max-height: 95vh;
    overflow-y: scroll;
    padding: 20px;
  }

  @media screen and (max-width: 350px) {
    padding: 15px;
  }
`;

const AgeVerificationWarningIcon = styled.img`
  width: 130px;
  display: block;
  margin: 0 auto 10px;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 18%;
    min-width: 90px;
  }
`;

const AgeVerificationWarning = styled.div`
  width: 90%;
  color: #d85358;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 55px;

  > div:first-of-type {
    font-size: 58px;
    font-weight: 700;
  }

  > div:last-of-type {
    max-width: 70%;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 1.5px;
    padding-left: 15px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    > div:first-of-type {
      width: auto;
      min-width: auto;
    }

    > div:last-of-type {
      max-width: 60%;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    flex-direction: column;
    margin-bottom: 20px;

    > div:first-of-type {
      font-size: 48px;
    }

    > div:last-of-type {
      max-width: 90%;
      width: 90%;
      padding-left: 0;
    }
  }
`;

const AgeButtonBlock = styled.div`
  width: 85%;
  margin: 0 auto 55px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 90%;
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const AgeButton = styled.div`
  width: 290px;
  height: 80px;
  background-color: ${props => (props.type === "confirm" ? "#d30093" : "#8f8f8f")};
  color: #ffffff;
  display: flex;
  align-items: center;
  padding: 5px 15px;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  > div:first-of-type {
    height: 90%;
    margin: auto 10px auto 0;

    img {
      height: 100%;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 48%;
    height: 70px;

    > div:first-of-type {
      height: 80%;
      margin: auto 10px auto 0;
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 100%;
    max-width: 240px;
    margin: 8px 0;
  }
`;

const AgeButtonText = styled.div`
  text-align: center;
  width: calc(100% - 70px);

  > div:first-of-type {
    font-size: ${props => (props.lang === "en" || props.lang === "ko" ? "24px" : "30px")};
    font-weight: 700;
  }

  > div:last-of-type {
    font-size: 22px;
    font-weight: 400;
    margin-top: -5px;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    > div:first-of-type {
      font-size: ${props => (props.lang === "en" || props.lang === "ko" ? "17px" : "22px")};
      line-height: 1.8;
      margin-bottom: ${props => (props.lang === "en" ? "6px" : "0")};
    }

    > div:last-of-type {
      font-size: ${props => (props.lang === "en" || props.lang === "ko" ? "15px" : "18px")};
      margin-top: -5px;
    }
  }
`;

const AgeVerificationFooter = styled.div`
  color: #ffffff;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.6;
`;

export {
  AgeVerificationContainer,
  AgeVerificationBlock,
  AgeVerificationWarning,
  AgeVerificationWarningIcon,
  AgeButtonBlock,
  AgeButtonText,
  AgeButton,
  AgeVerificationFooter,
};
