import React from "react";
import styled from "@emotion/styled";
import { MetaDataHead } from "../components";
import logoIcon from "../assets/images/sub-logo-en.png";
import { BREAK_POINTS } from "../utils/style";

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url("${process.env.GATSBY_ROOT_PATH}/bg-02.jpg") center no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.5s ease-in;
`;

const LoadingBlock = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingLogo = styled.img`
  width: 240px;
  margin: 0 auto 10px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 180px;
  }
`;

const LoadingText = styled.div`
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 2px;
  text-align: center;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    font-size: 12px;
  }
`;

export default function Loading({ style }) {
  return (
    <>
      <MetaDataHead>
        <link rel="preload" href={`${process.env.GATSBY_ROOT_PATH}/bg-02.jpg`} as="image" />
      </MetaDataHead>
      <LoadingContainer style={style}>
        <LoadingBlock>
          <LoadingLogo src={logoIcon} alt="logo" />
          <LoadingText>NOW LOADING ...</LoadingText>
        </LoadingBlock>
      </LoadingContainer>
    </>
  );
}
