import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { CharacterListContainer, CharacterListTitle } from "./style";
import { LANGS_MAP_FOR_ASSETS } from "../../utils/languages";

export default function CharacterListWrapper({ children }) {
  const intl = useIntl();
  const { locale } = intl;
  const lang = LANGS_MAP_FOR_ASSETS[locale];

  return (
    <CharacterListContainer id="characters">
      <CharacterListTitle>
        <picture>
          <source
            srcSet={
              require(`../../assets/images/characterListWebp/caption-03-${lang}.webp`).default
            }
            type="image/webp"
          />
          <img
            src={require(`../../assets/images/characterList/caption-03-${lang}.png`).default}
            alt="intro-title"
          />
        </picture>
      </CharacterListTitle>

      {children}
    </CharacterListContainer>
  );
}
