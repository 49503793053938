import React, { useContext, useState, createContext } from "react";

const DMMPopupState = createContext({
  isOpen: true,
  setIsOpen: () => {},
});

export const useDMMPopupState = () => useContext(DMMPopupState);

export const DMMPopupContextProvider = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <DMMPopupState.Provider value={{ isOpen, setIsOpen }}>{props.children}</DMMPopupState.Provider>
  );
};
