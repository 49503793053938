import styled from "@emotion/styled";
import { containerStyle } from "../../utils/style";
import previewImgBg from "../../assets/images/game-intro-bg.png";
import { BREAK_POINTS } from "../../utils/style";

const IntroContainer = styled.section`
  ${containerStyle};
  padding-top: 20px;
`;

const IntroTitle = styled.h2`
  width: 769px;
  height: 161px;
  margin: 0 auto;
  z-index: 3;
  position: relative;

  img {
    width: 100%;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 100vw;
    height: calc(100vw * 161 / 769);
  }
`;

const IntroMainImage = styled.img`
  width: 80%;
  display: block;
  margin: -6vw auto 0;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 180%;
    margin: -6vw -40% 0;
  }
`;

const IntroContent = styled.section`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: -40vw auto 0;

  @media screen and (max-width: ${BREAK_POINTS.xl}) {
    .intro__sideImage {
      width: calc((100% - 700px) / 2);
    }
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    margin: -90vw auto 0;

    .intro__sideImage {
      display: none;
    }
  }
`;

const IntroDescription = styled.img`
  width: 680px;

  @media screen and (max-width: ${BREAK_POINTS.md}) {
    width: 85%;
  }

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const IntroPreviewImagesCenter = styled.section`
  width: 850px;
  height: 245px;
  background: url(${previewImgBg}) center top/contain no-repeat;
  margin: 0 auto 100px;
  text-align: center;
  position: relative;

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 95vw;
    height: calc(95vw * 245 / 850);
    margin-bottom: 70px;
  }
`;

const IntroPreviewImage = styled.img`
  position: absolute;
  cursor: pointer;
  display: inline;

  &:first-of-type {
    left: 20px;
    top: 0;
  }

  &:nth-of-type(2) {
    left: 205px;
    top: 45px;
  }

  &:nth-of-type(3) {
    left: 385px;
    top: -5px;
  }

  &:nth-of-type(4) {
    left: 565px;
    top: -10px;
  }

  &:hover {
    transform: scale(1.05);
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 20%;

    &:first-of-type,
    &:nth-of-type(2),
    &:nth-of-type(3),
    &:nth-of-type(4) {
      position: relative;
      left: auto;
      top: auto;
      margin-top: 4%;
    }
  }
`;

export {
  IntroContainer,
  IntroTitle,
  IntroMainImage,
  IntroDescription,
  IntroContent,
  IntroPreviewImagesCenter,
  IntroPreviewImage,
};
