import React from "react";
import { useIntl } from "gatsby-plugin-intl";
import { MetaDataHead } from "../../components";
import {
  AgeVerificationContainer,
  AgeVerificationBlock,
  AgeVerificationWarning,
  AgeVerificationWarningIcon,
  AgeButtonBlock,
  AgeButtonText,
  AgeButton,
  AgeVerificationFooter,
} from "./style";
import warnIcon from "../../assets/images/icon-warn.png";
import confirmIcon from "../../assets/images/icon-confirm.png";
import declineIcon from "../../assets/images/icon-decline.png";

export default function AgeVerification({ handleConfirm, handleDecline }) {
  const intl = useIntl();
  const { locale, formatMessage } = intl;

  return (
    <>
      <MetaDataHead>
        <link rel="preload" href={`${process.env.GATSBY_ROOT_PATH}/bg-01.jpg`} as="image" />
      </MetaDataHead>
      <AgeVerificationContainer>
        <AgeVerificationBlock>
          <AgeVerificationWarningIcon src={warnIcon} alt="warn-icon" />
          <AgeVerificationWarning>
            <div>{formatMessage({ id: "age-warning" })}</div>
            <div>{formatMessage({ id: "age-warning-desc" })}</div>
          </AgeVerificationWarning>

          <AgeButtonBlock>
            <AgeButton type="confirm" onClick={handleConfirm}>
              <div>
                <img src={confirmIcon} alt="confirm-icon" />
              </div>
              <AgeButtonText lang={locale}>
                <div>{formatMessage({ id: "age-confirm" })}</div>
                <div>ENTER SITE</div>
              </AgeButtonText>
            </AgeButton>
            <AgeButton type="decline" onClick={handleDecline}>
              <div>
                <img src={declineIcon} alt="decline-icon" />
              </div>
              <AgeButtonText lang={locale}>
                <div>{formatMessage({ id: "age-decline" })}</div>
                <div>LEAVE SITE</div>
              </AgeButtonText>
            </AgeButton>
          </AgeButtonBlock>

          <AgeVerificationFooter>
            {formatMessage({ id: "age-footer" })
              .split("\n")
              .map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
          </AgeVerificationFooter>
        </AgeVerificationBlock>
      </AgeVerificationContainer>
    </>
  );
}
