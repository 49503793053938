import styled from "@emotion/styled";
import { containerStyle, BREAK_POINTS } from "../../utils/style";

const CharacterListContainer = styled.section`
  ${containerStyle}
  padding-top: 30px;
  padding-bottom: 100px;
`;

const CharacterListTitle = styled.h2`
  width: 769px;
  margin: 0 auto;
  z-index: 3;
  position: relative;

  img {
    width: 100%;
  }

  @media screen and (max-width: ${BREAK_POINTS.lg}) {
    width: 95%;
    max-width: 760px;
  }
`;

export { CharacterListContainer, CharacterListTitle };
