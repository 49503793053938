// noinspection AllyJsxHardcodedStringInspection

import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import cx from "classnames";
import { useDMMPopupState } from "../hooks/useDMMPopupState";

const URL_SCHEMA = "dmmgameplayer://tenkafuma/cl/adult";

export const DMMPopup = () => {
  const { isOpen, setIsOpen } = useDMMPopupState();
  const [isMobile, setIsMobile] = useState(false);
  const [isWindows, setIsWindows] = useState(true);
  const [isInstalled, setIsInstalled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  let timerInstallCheck;
  let timerBtnDisabled;

  const stopTimeout = () => clearTimeout(timerInstallCheck);

  const startPlayer = event => {
    event.preventDefault();
    clearTimeout(timerInstallCheck);
    timerInstallCheck = setTimeout(function () {
      window.removeEventListener("blur", stopTimeout);
      setIsInstalled(false);
    }, 5000);

    window.focus();
    window.addEventListener("blur", stopTimeout);

    const iframe = document.createElement("iframe");
    iframe.src = URL_SCHEMA;
    iframe.style.display = "none";
    document.body.appendChild(iframe);

    setIsButtonDisabled(true);
    clearTimeout(timerBtnDisabled);
    timerBtnDisabled = setTimeout(function () {
      setIsButtonDisabled(false);
    }, 5000);
  };

  useEffect(() => {
    const ua = navigator.userAgent;

    if (
      ua.indexOf("iPhone") > 0 ||
      ua.indexOf("iPod") > 0 ||
      (ua.indexOf("Android") > 0 && ua.indexOf("Mobile") > 0) ||
      ua.indexOf("Windows Phone") > 0 ||
      ua.indexOf("iPad") > 0
    ) {
      setIsMobile(true);
    } else if (ua.toLowerCase().indexOf("win") === -1) {
      setIsWindows(false);
    }
  }, []);

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-black/80" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95">
              <Dialog.Panel className="w-full transform overflow-hidden align-middle font-bold rounded-[36px] bg-black">
                <div className="dmm-popup">
                  <div id="w">
                    <div id="header">
                      <h1>DMM GAME PLAYER はお持ちですか？</h1>
                      <p>
                        このゲームをプレイするためには、DMM GAME
                        PLAYERをインストールしておく必要があります。
                      </p>
                    </div>
                    <div className="container">
                      <p
                        className={cx(
                          "tx-warning",
                          isWindows ? "tx-warning--win" : "is-notwindow"
                        )}>
                        このゲームはWindows専用です。
                      </p>
                      <p className={cx("tx-warning", isMobile ? "is-notwindow" : "tx-warning--pc")}>
                        このゲームはPC専用です。
                      </p>
                      <p
                        className={cx("tx-install", {
                          "is-notinstalled": !isInstalled,
                        })}>
                        DMM GAME PLAYER
                        が起動しない場合は、ダウンロードボタンからダウンロードをしてください。
                      </p>
                      <ul
                        className={cx("box-btn", {
                          inactive: isMobile || !isWindows,
                          "is-btn-disabled": isButtonDisabled,
                        })}>
                        <li className="start-enabled">
                          <p>インストール済みの方</p>
                          <a href={URL_SCHEMA} className="btn btn-start" onClick={startPlayer}>
                            <span>今すぐプレイ！</span>
                          </a>
                        </li>
                        <li className="start-disabled">
                          <p>インストール済みの方</p>
                          <div className="btn btn-loading">
                            <img src="https://www.dmm.co.jp/images/common/ico/loading.gif" alt="" />
                            <span>処理中です･･･</span>
                          </div>
                        </li>
                        <li>
                          <p>未インストールの方</p>
                          <a
                            href="https://site-gameplayer.dmm.com/gameplayer/game/installer/win/x86/tenkafuma/cl/adult"
                            target="_blank"
                            className="btn btn-install is-win">
                            <span>
                              DMM GAME PLAYER
                              <br />
                              インストール(Windows版)
                            </span>
                          </a>
                        </li>
                      </ul>
                      <div
                        className={cx("link-player", {
                          "is-notinstalled": !isInstalled,
                        })}>
                        <a
                          href="http://www.dmm.co.jp/netgame/top/guide/playerguide_html/=/ch_navi=/"
                          target="_blank">
                          DMM GAME PLAYERとは？
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
