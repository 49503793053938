import React from "react";
import styled from "@emotion/styled";
import "../assets/css/global.css";
import { NavbarPC, NavbarMobile, ScrollToTop, Footer, MetaDataHead, DMM } from "../components";
import { Loading, Home, Intro, CharacterListWrapper, CharacterList } from "../views";
import { useLoaded } from "../hooks";
import bgCover from "../assets/images/overlay-02.png";
import { DMMPopup } from "../components/DMMPopup";

const RootContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  animation: fadeIn 1s;
`;

const VideoContainer = styled.video`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -10;
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  top: 0;
  left: 0;
  background: url(${bgCover}) center repeat;
  background-size: 100%;
  z-index: 1;
`;

export default function Root() {
  const { loaded } = useLoaded();

  const getVideoByWidth = () => {
    if (typeof window !== "undefined") {
      return window.innerWidth >= 768
        ? `${process.env.GATSBY_ROOT_PATH}/op.mp4`
        : `${process.env.GATSBY_ROOT_PATH}/op-mobile.mp4`;
    }

    return `${process.env.GATSBY_ROOT_PATH}/op.mp4`;
  };

  return (
    <>
      {<Loading style={loaded ? { opacity: 0, zIndex: "-40" } : { opacity: 1, zIndex: "40" }} />}
      <MetaDataHead>
        {/* <link
              rel="preload"
              href={`${process.env.GATSBY_ROOT_PATH}/op.mp4`}
              as="video"
              type="video/mp4"
              media="(min-width: 768px)"
            />
            <link
              rel="preload"
              href={`${process.env.GATSBY_ROOT_PATH}/op-mobile.mp4`}
              as="video"
              type="video/mp4"
              media="(max-width: 767px)"
            /> */}
      </MetaDataHead>
      <RootContainer>
        <VideoContainer
          id="bg-video"
          preload="auto"
          autoPlay
          loop
          muted
          playsInline
          src={getVideoByWidth()}
          type="video/mp4"
        />
        <ContentContainer id="content-container">
          <DMM />
          <NavbarPC />
          <NavbarMobile />
          <ScrollToTop />

          <Home />

          <Intro />
          <CharacterListWrapper>
            <CharacterList />
          </CharacterListWrapper>
          <Footer />
        </ContentContainer>
      </RootContainer>
      <DMMPopup />
    </>
  );
}
